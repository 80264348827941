import { Col, Modal, Row, theme } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { GET } from "../../frameworks/HttpClient";
import { URL_DELIVERY, URL_PLANTATIONS } from "../../constants/urls";
import * as _ from "lodash";
import TSTable from "../common/TSTable";
import ErrorMessages from "../common/ErrorMessages";
import ColorButton from "../common/ColorButton";
import { PATH } from "../../routes/CustomRoutes";
import { EyeOutline } from "antd-mobile-icons";


export default function FactoryDeliveryModal (props) {
  const {
    open,
    target,
    onClose,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [detail, setDetail] = React.useState({ id: null, sender: '-', code: '-' })
  const tableRef = React.useRef();
  const { token: { colorInfo } } = theme.useToken();

  const columns = [
    {
      title: t("plantations.plantation_code"),
      width: 120,
      dataIndex: "code",
      searcher: true,
      render: (value) => value ? value : '-',
    },
    {
      title: "Overall Area",
      width: 120,
      dataIndex: "overall_area",
    },
    {
      title: "Overlap Area",
      dataIndex: "overlap_layers_detail",
      width: 200,
      render: (value) => {
        if (value) {
          return (
            <ul>
              { value.map(element => (
                <li>{t(`geography_layer_types.${_.get(element, 'type')}`)} : {_.get(element, 'name')}</li>
              )) }
            </ul>
          )
        }
        return <div>-</div>
      }
    },
    {
      title: " ",
      dataIndex: "id",
      width: 50,
      render: (value) => <div style={{ textAlign: 'center' }}>
        <ColorButton
          type='primary'
          override={colorInfo}
          icon={<EyeOutline/>}
          onClick={() => window.open(`${PATH.PLANTATION_DETAIL}/${value}`, '_blank')} />
      </div>
    }
  ]

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${target}/`)
      setDetail({ ...detail, ...response.data })
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (target) {
      fetchData()
    }
  }, [target])

  React.useEffect(() => {
    if (detail.id && tableRef.current) {
      tableRef.current.fetch();
    }
  }, [detail.id])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon
      title={t('plantations.detail')}
      width={1000}
      footer={[]}>
      <ErrorMessages message={errorMessages}/>
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col md={8} sm={24}>
          <span style={{ fontWeight: 'bold' }}>Document No. :</span> {detail.code}
        </Col>
        <Col md={16} sm={24}>
          <span style={{ fontWeight: 'bold' }}>Supplier :</span> {detail.sender}
        </Col>
      </Row>
      <TSTable
        ref={tableRef}
        columns={columns}
        url={URL_PLANTATIONS.PLANTATIONS}
        params={{ delivery_transaction: detail.id }}
        autoFetch={false}
        loading={loading}
      />
    </Modal>
  )
}