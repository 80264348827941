import React from 'react';
import { theme, Popconfirm, message, Space, Tooltip, Row, Col } from 'antd';
import TSTable from '../components/common/TSTable';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, UnlockOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { SECONDARY_COLOR } from '../constants/color';
import FactoryModal from '../components/factory/FactoryModal';
import { URL_AUTH, URL_MEMBER } from '../constants/urls';
import { GET, DELETE } from '../frameworks/HttpClient';
import ColorButton from '../components/common/ColorButton';
import ChangePasswordModal from '../components/user/ChangePasswordModal';
import dayjs from 'dayjs';
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from '../constants/string';
import { useTranslation } from 'react-i18next';

export default function FactoryListView() {
  const [openFactoryModal, setOpenFactoryModal] = React.useState(false);
  const [targetFactory, setTargetFactory] = React.useState(null);
  const [targetChangePW, setTargetChangePW] = React.useState(null);
  const { t, i18n } = useTranslation();
  const tableRef = React.useRef(null);

  const {
    token: { colorWarning, colorSuccess }
  } = theme.useToken();

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_MEMBER.FACTORY}${id}/`)
      tableRef.current.fetch();
      message.success(t('alert_factery_deleted'))
    } catch (error) {
      message.error(error.errorMessages)
    }

  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("factory"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("factory"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("factory")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      searcher: true,
    },
    {
      title: t("factory_name"),
      dataIndex: i18n.language === 'th' ? "name" : "name_en",
      sorter: true,
      searcher: true,
    },
    {
      title: t("contact_name"),
      dataIndex: 'first_name',
      searcher: true,
    },
    {
      title: t("phone_number"),
      width: 180,
      dataIndex: "phone",
      searcher: true,
    },
    {
      title: t("collecting_centers.license_number"),
      width: 200,
      dataIndex: 'license_number',
      searcher: true,
    },
    {
      title: 'Action',
      width: 140,
      dataIndex: 'actions',
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space direction='horizontal'>
              <Tooltip title={t("edit")}>
                <ColorButton
                  type={"primary"}
                  style={{background: colorWarning}}
                  icon={<EditOutlined />}
                  danger
                  onClick={() => setTargetFactory(original)}/>
                </Tooltip>
              <Tooltip title={t('delete')}>
              <Popconfirm
                title={t("msg_confirm_delete", {name : t("factory")})}
                onConfirm={() => handleDelete(original.id)}
                okText={t("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}>
                <ColorButton 
                  type={"primary"} 
                  danger
                  icon={<DeleteOutlined />}/>
              </Popconfirm>
              </Tooltip>
              <Tooltip title={t("change_password")}>
                <ColorButton 
                  type={"primary"} 
                  style={{background: colorSuccess}}
                  icon={<UnlockOutlined/>}
                  danger
                  onClick={() => {
                    setTargetChangePW(original.user)
                  }} />
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <Space style={{ float: 'right' , marginBottom: '1rem'}}>
      <Row gutter={8}>
        <Col>
          <ColorButton
            type={"primary"}
            override={SECONDARY_COLOR}
            icon={<PlusCircleOutlined />}
            style={{ float: "right", marginLeft : 8 }}
            onClick={() => setOpenFactoryModal(true)}>
            {t("factorys.add")}
          </ColorButton>
          <ColorButton
            icon={<FileExcelOutlined />}
            style={{float: "right",  marginLeft : 8 }}
            onClick={() => exportTable("excel")}>
            Excel
          </ColorButton>
          <ColorButton
            icon={<FilePdfOutlined />}
            style={{float: "right", marginLeft : 8 }}
            onClick={() => exportTable("pdf")}>
            PDF
          </ColorButton>
        </Col>
      </Row>
      </Space>
      <TSTable 
        ref={tableRef}
        columns={columns}
        url={URL_MEMBER.FACTORY}
        rowKey={"id"}
        size={"small"}
        pageSize={10}/>

      <FactoryModal 
        open={(openFactoryModal || targetFactory) ? true : false}
        target={targetFactory}
        onUpdated={() => {
          if (openFactoryModal)
            setOpenFactoryModal(false)
          if (targetFactory)
            setTargetFactory(null)
          refreshData();
        }}
        onClose={() => {
          if (openFactoryModal)
            setOpenFactoryModal(false)
          if (targetFactory)
            setTargetFactory(null)
        }}/>
      
      <ChangePasswordModal 
        open={targetChangePW ? true : false}
        url={`${URL_AUTH.USER}${targetChangePW ? targetChangePW : ""}/change_password/`}
        onClose={() => setTargetChangePW(null)}/>
    </div>
  )
}