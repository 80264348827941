import React from 'react';
import { URL_DELIVERY, URL_REPORT } from '../../constants/urls';
import { HUMAN_DATETIME_FORMAT, DATETIME_FORMAT } from '../../constants/string';
import moment from 'moment';
import TSTable from '../common/TSTable';
import ColorButton from '../common/ColorButton';
import { DeleteOutlined, QrcodeOutlined, PlusCircleOutlined ,EditOutlined ,FileDoneOutlined, FilePdfOutlined, FileExcelOutlined} from '@ant-design/icons';
import { SECONDARY_COLOR } from '../../constants/color';
import ShippingInstructionModal from './ShippingInstructionModal';
import { Card, List, Pagination, Popconfirm, Space, theme, Row, Col, message } from 'antd';
import ErrorMessages from '../common/ErrorMessages';
import { DELETE, GET,  } from '../../frameworks/HttpClient';
import ShippingInfomationModa from '../collectingcenter/ShippingInfomationModa';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { useTranslation } from 'react-i18next';
import Meta from 'antd/es/card/Meta';
import { Button } from 'antd-mobile';
import dayjs from 'dayjs';


export default function FactoryShippingInstructionView() {
  const {
    token: { colorError, colorWarning, colorInfo, colorPrimary}
  } = theme.useToken();
  const [messageApi, msgContextHolder] = message.useMessage();
  const tableRef = React.useRef();
  const [SITarget, setSITarget] = React.useState(null);
  const [SiInfomation, setSiInfomation] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [dataSi, setDataSi] = React.useState([]);
  const [total, setTotal] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const { t } = useTranslation();

  const fetchDataSi = async(page) => {
    try {
      const response = await GET(`${URL_DELIVERY.SHIPPING_INSTRUCTION}?page_size=3&page=${page ? page : 1}`)
      setDataSi(response.data.results)
      setTotal(response.data.total)
    } catch (error) {

    }
  } 

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("shipping_Instruction_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("shipping_Instruction_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("shipping_Instruction_list")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const handleDelete = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await DELETE(`${URL_DELIVERY.SHIPPING_INSTRUCTION}${id}/`)
      if (tableRef.current) {
        tableRef.current.fetch()
      } fetchDataSi();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleDownloadReportExcel = async (id) => {
    try {
      setLoading(true)
      const response = await GET(`${URL_REPORT.PLANTATION_SI}${id}/`);
      window.open(response.requestUrl, '_blank');
    } catch (error) {
      setErrorMessages('Error loading excel report', error);
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(()=>{
    fetchDataSi();
  },[])

  const columns = [
    {
      title: t("si.date_time"),
      dataIndex: 'created',
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: 'Bill No.',
      dataIndex: 'code',
      sorter: true, 
      searcher : true,
    },
    {
      title: t("si.del_number"),
      dataIndex: 'del_detail_list',
      width: "50%",
      render : (value) => {
        let data = []
        value.map(item => {
          data = [...data,`${item.code} - ${item.sender}`]
        })
        return(
          data.map((del,index) => {
            return(
              <div>{del}</div>
            )}
          )
        )
      },
      renderReport : (value) => {
        let data = []
        value.map(item => {
          data = [...data,`${item.code}`]
        })
        return(
          `${data}`
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      skipReport: true,
      width: 150,
      render: (value) => (
        <div style={{ textAlign: 'center' }}>
          <Space>
            <ColorButton
            type='primary'
            override={colorInfo}
            onClick={() => setSiInfomation(value)}
            icon={<QrcodeOutlined />}/>
            <ColorButton
              type='primary'
              override={colorWarning}
              loading={loading}
              icon={<EditOutlined/>}
              onClick={() => setSITarget(value)}
              />
            <ColorButton
              type='primary'
              override={colorPrimary}
              loading={loading}
              icon={<FileExcelOutlined />}
              onClick={() => handleDownloadReportExcel(value)}
            />
            <Popconfirm
              title={t("si.delete_confirm")}
              onConfirm={() => handleDelete(value)}
              okText={t("delete")}
              okButtonProps={{ danger: true }}
              cancelText={t("cancel")}
              placement='topRight'
            >
              <ColorButton 
                type='primary'
                override={colorError}
                icon={<DeleteOutlined />}/>
            </Popconfirm>
          </Space>
        </div>
      )
    },
  ]
  return (
    <div>
      {msgContextHolder}
      {isOnMobile ? (
        <div style={{textAlign:'center'}}>
          <ColorButton 
            block color='warning'
            size='large'
            icon={<PlusCircleOutlined />} 
            type={"primary"}
            override={SECONDARY_COLOR}
            onClick={() => setSITarget('add')}>
            {t("si.add_si")}
          </ColorButton>
        </div>
      ):(
        <Space style={{ float: 'right' }}>
            <Row gutter={8}>
              <Col>
                <ColorButton
                  icon={<FilePdfOutlined />}
                  style={{float: "right"}}
                  onClick={() => exportTable("pdf")}>
                  PDF
                </ColorButton>
              </Col>
              <Col>
                <ColorButton
                  icon={<FileExcelOutlined />}
                  style={{float: "right", marginBottom : 16}}
                  onClick={() => exportTable("excel")}>
                  Excel
                </ColorButton>
            </Col>
              <Col>
                <ColorButton
                  type='primary'         
                  override={SECONDARY_COLOR}
                  icon={<PlusCircleOutlined/>}
                  onClick={() => setSITarget('add')}>
                  {t("si.add_si")}
                </ColorButton>
              </Col>
          </Row>
        </Space>)}
      {isOnMobile? (
      <div>
        <List 
        dataSource={dataSi}
        renderItem={(item) => (
        <List.Item
          key={item.id}>
            <Card
              style={{ width: '800px' }}
              title={<p>{t("si_number")} : {item.code}</p>}
              actions={[
                <EditOutlined onClick={() => setSITarget(item.id)} />,
                <FileDoneOutlined onClick={() => setSiInfomation(item.id)} />,
                <Popconfirm
                  title={t("collecting_centers.delete_title")}
                  description={t("would_you_like_to_delete_this_subscriber")}
                  onConfirm={() => handleDelete(item.id)}
                  okText={t("delete")}
                  okButtonProps={{ danger: true }}
                  cancelText={t("cancel")}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ]}
            >
              <Meta
                description={
                  <>
                    <>{t("si.date_time")} {moment(item.created).format(HUMAN_DATETIME_FORMAT)}</>
                    <br />
                    <p style={{ fontSize: '14px' }}>
                      {t("si.select_del")}
                      {item.del_detail_list.map((data, index) => (
                        <div key={index}>
                          {`[${data.code}]- ${data.product_name}(${data.sender_name})`}
                        </div>
                      ))}
                    </p>
                  </>
                }
              />
            </Card>
        </List.Item>)}
        >
        </List>
          <div style={{textAlign:'center', marginTop: '16px'}}>
          <Pagination
              defaultPageSize={3}
              defaultCurrent={1}
              current={page}
              total={total}
              onChange={(page) => {
                setPage(page)
                fetchDataSi(page)
              }}
            />
          </div>
      </div>
     ):(
      <div>
        <ErrorMessages message={errorMessages}/> 
        <TSTable 
          ref={tableRef}
          columns={columns}
          url={URL_DELIVERY.SHIPPING_INSTRUCTION}
          rowKey={'id'}
          size={'small'}
          pageSize={10}/>
      </div>)}
      <ShippingInstructionModal
        open={SITarget != null}
        target={SITarget}
        onSuccess={(data) => {
          setSITarget(null)
          if (tableRef.current) {
            tableRef.current.fetch()
          }
          fetchDataSi();
          messageApi.open({
            type:'success',
            content: `บันทึก SI [${data.code}] เสร็จสิ้น`
          })
        }}
        onClose={() => {
          setSITarget(null)
        }}/>

        <ShippingInfomationModa
          open={SiInfomation}  
          target={SiInfomation}
          onClose={() => {setSiInfomation(false) 
          }}
        />
    </div>
  )
}