import React from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Image, Col, Space, Layout, Row, message, Spin, Tag, Popover } from "antd";
import { List, Grid, Button, Selector, Badge } from 'antd-mobile'
import { List as ListAntd } from "antd";
import { GET } from "../frameworks/HttpClient";
import { URL_DELIVERY, URL_REPORT, URL_GEOGRAPHY } from "../constants/urls";
import { GEOGRAPHY_LAYER_TYPES, GEOGRAPHY_LAYER_COLOR } from "../constants/string";
import ErrorMessages from "../components/common/ErrorMessages";
import { useParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, LayersControl, Popup, Polygon, LayerGroup } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as _ from 'lodash';
import { Content, Footer } from "antd/es/layout/layout";
delete L.Icon.Default.prototype._getIconUrl;

export default function ShippingInfomationScreen() {
  const MAP_CENTER = [13.736717, 100.52318]
  const params = useParams();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = React.useState(null);
  const [siCode, setSiCode] = React.useState([]);
  const [datadDel, setDataDel] = React.useState([]);
  const [plantation, setPlantation] = React.useState([]);
  const [geoLayers, setGeoLayers] = React.useState([]);
  const [deForestLayer, setDeforestLayer] = React.useState([]);
  const [nationalForestLayer, setNationalForestLayer] = React.useState([]);
  const [protectedAreaLayer, setProtectedAreaLayer] = React.useState([]);
  const [forestCoverLayer, setForestCoverLayer] = React.useState([]);
  const [reservedForestLayer, setReservedForestLayer] = React.useState([]);
  const [nonHuntingLayer, setNonHuntingLayer] = React.useState([]);

  const GEOGRAPHY_LAYER_KEY = "area"
  const PLANTATION_LAYER_OPTIONS = [
    { 
      key: GEOGRAPHY_LAYER_TYPES.DE_FOREST, 
      value: GEOGRAPHY_LAYER_TYPES.DE_FOREST, 
      label: (
        <Popover content={
          <div>
            <div style={{fontWeight: "bold"}}>Data set details: Forest Change</div>
            <div>
              Results from time series analysis of Landsat images to classify the extent and change of
              Forests around the world from 2020 to 2023 using Landsat 8 OLI for 2020 onwards and Landsat 9 OLI
              For the year 2022 onwards, with reference to strip photos multispectral with composite imagery in the band
              4 spectral bands for Landsat spectral bands red, NIR, SWIR1, and SWIR2 (Landsat 8/9 bands 4, 5,
              6, and 7). and adjust Normalized top-of-atmosphere (TOA) reflectance values ​​(ρ) 8-bit data scale and have
              The spatial resolution is approximately 30 meters per pixel. For more information on these results, see
              More information can be found in the relevant journal (Hansen et al., Science 2013).
            </div>
            <br />
            <div style={{fontWeight: "bold"}}>Citations:</div>
            <div>- Source: Hansen/UMD/Google/USGS/NASA</div>
            <div>
              - Hansen, M. C., P. V. Potapov, R. Moore, M. Hancher, S. A. Turubanova, A. Tyukavina, D. Thau,
              S. V. Stehman, S. J. Goetz, T. R. Loveland, A. Kommareddy, A. Egorov, L. Chini, C. O. Justice,
              and J. R. G. Townshend. 2013. "High-Resolution Global Maps of 21st-Century Forest Cover
              Change." Science 342 (15 November): 850-53. 10.1126/science.1244693
            </div>
          </div>
        }>
          <Tag color={GEOGRAPHY_LAYER_COLOR.DE_FOREST} style={{margin: 0}}>
            EU Deforestation
          </Tag>
        </Popover>
      )
    },
    { 
      key: GEOGRAPHY_LAYER_TYPES.NATIONAL_PARK, 
      value: GEOGRAPHY_LAYER_TYPES.NATIONAL_PARK, 
      label: (
        <Tag color={GEOGRAPHY_LAYER_COLOR.NATIONAL_PARK} style={{margin: 0}}>
          National Park
        </Tag>
      )
    },
    { 
      key: GEOGRAPHY_LAYER_TYPES.NON_HUNTING, 
      value: GEOGRAPHY_LAYER_TYPES.NON_HUNTING, 
      label: (
        <Tag color={GEOGRAPHY_LAYER_COLOR.NON_HUNTING} style={{margin: 0}}>
          Non Hunting
        </Tag>
      )
    },
    {
      key: GEOGRAPHY_LAYER_TYPES.PROTECTED_AREA,
      value: GEOGRAPHY_LAYER_TYPES.PROTECTED_AREA,
      label: (
        <Tag color={GEOGRAPHY_LAYER_COLOR.PROTECTED_AREA} style={{ margin: 0 }}>
          Protected Area
        </Tag>
      )
    },
    {
      key: GEOGRAPHY_LAYER_TYPES.FOREST_COVER,
      value: GEOGRAPHY_LAYER_TYPES.FOREST_COVER,
      label: (
        <Tag color={GEOGRAPHY_LAYER_COLOR.FOREST_COVER} style={{ margin: 0 }}>
          Forest Cover
        </Tag>
      )
    },
    {
      key: GEOGRAPHY_LAYER_TYPES.RESERVED_FOREST,
      value: GEOGRAPHY_LAYER_TYPES.RESERVED_FOREST,
      label: (
        <Tag color={GEOGRAPHY_LAYER_COLOR.RESERVED_FOREST} style={{ margin: 0 }}>
          Reserved Forest
        </Tag>
      )
    },
  ]

  const iconMarker = new L.Icon({
    iconUrl: require('../assets/marker-2.png'),
    iconRetinaUrl: require('../assets/marker-2.png'),
    iconAnchor: [20, 40],
    iconSize: [40, 40],
  });

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await Promise.all([
        GET(`${URL_DELIVERY.PUBLIC_SHIPPING_INSTRUCTION}${params.id}/`),
        GET(`${URL_DELIVERY.PLANTATIONS_SOURCE_SI_TRACKING}${params.id}`)
      ])
      setSiCode(response[0].data)
      setDataDel(response[0].data.del_detail_list)
      setPlantation(response[1].data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setIsLoading(false) }
  }

  const fetchGeographicLayer = async (layerType, params, callbackFunc) => {
    try {
      setIsLoading(true)
      const response = await GET(
        `${URL_GEOGRAPHY.LAYER_DETAIL}${layerType}/`,
        params)

      callbackFunc(
        response.data.results.flatMap(layer => {
          const coodinates = JSON.parse(layer.polygons)['coordinates']
          return coodinates.map(cood =>
          (
            {
              [GEOGRAPHY_LAYER_KEY]: cood.map(data => data.map(([lat, long]) => {
                return [long, lat]  // convert backend lib to frontend long, lat
              }))
            }
          )
          )
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setIsLoading(false) }
  }

  const renderDeforestationLayer = (item) => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.DE_FOREST} positions={item[GEOGRAPHY_LAYER_KEY]}/>
      </LayerGroup>
    )
  }

  const renderNationalForestLayer = item => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.NATIONAL_PARK} positions={item[GEOGRAPHY_LAYER_KEY]} />
      </LayerGroup>
    )
  }

  const renderNonHuntingLayer = item => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.NON_HUNTING} positions={item[GEOGRAPHY_LAYER_KEY]} />
      </LayerGroup>
    )
  }

  const renderProtectedAreaLayer = item => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.PROTECTED_AREA} positions={item[GEOGRAPHY_LAYER_KEY]} />
      </LayerGroup>
    )
  }

  const renderForestCoverLayer = item => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.FOREST_COVER} positions={item[GEOGRAPHY_LAYER_KEY]} />
      </LayerGroup>
    )
  }

  const renderReservedForestLayer = item => {
    return (
      <LayerGroup>
        <Polygon color={GEOGRAPHY_LAYER_COLOR.RESERVED_FOREST} positions={item[GEOGRAPHY_LAYER_KEY]} />
      </LayerGroup>
    )
  }

  const renderPlantationPolygons = (item) => {
    const data = item.polygons.map((polygon) => {
      return polygon.polygon.map((obj) => [obj.latitude, obj.longitude])
    })
    
    return (
      <LayerGroup>
        <Polygon positions={data}/>
      </LayerGroup>
    )
    
  }

  const handleDownloadGeoJSON = async () => {
    try{
      setIsLoading(true)
      const response = await GET(`${URL_DELIVERY.PLANTATION_GEOJSON}${params.id}/?format=json`)
      window.open(response.requestUrl, '_blank');
    } catch(error) {
      console.error('Error loading GeoJSON')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadReportDDR = async () => {
    try {
      setIsLoading(true)
      const response = await GET(`${URL_REPORT.DDR_REPORT}?si=${params.id}`);
      window.open(response.requestUrl, '_blank');
    } catch (error) {
      setErrorMessages('Error loading DRR Report', error);
    } finally {
      setIsLoading(false)
    }
  }

  const cardStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.DE_FOREST) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.DE_FOREST,
      { si: params.id }, 
      setDeforestLayer
    ) : setDeforestLayer([])

    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.NATIONAL_PARK) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.NATIONAL_PARK,
      { },
      setNationalForestLayer
    ) : setNationalForestLayer([])

    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.NON_HUNTING) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.NON_HUNTING,
      { si: params.id },
      setNonHuntingLayer
    ) : setNonHuntingLayer([])

    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.PROTECTED_AREA) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.PROTECTED_AREA,
      { si: params.id },
      setProtectedAreaLayer
    ) : setProtectedAreaLayer([])

    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.FOREST_COVER) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.FOREST_COVER,
      { si: params.id },
      setForestCoverLayer
    ) : setForestCoverLayer([])

    geoLayers.includes(GEOGRAPHY_LAYER_TYPES.RESERVED_FOREST) ? fetchGeographicLayer(
      GEOGRAPHY_LAYER_TYPES.RESERVED_FOREST,
      { si: params.id },
      setReservedForestLayer
    ) : setReservedForestLayer([])
  }, [geoLayers])

  return (
    <Layout>
      <Content>
    <Space
      direction="vertical"
      style={{ width: '100%' }}
      size={[0, 48]}>
      <Spin spinning={isLoading}>
        {contextHolder}
        <Layout>
          <div style={{ textAlign: 'center' }}>
            <br />
            <Image
              style={{ textAlign: 'center', width: "100%", maxWidth: 120 }}
              preview={false}
              src={"/logo_txt.png"} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <p>SI: {siCode.code} </p>
            <p>Factory: {siCode.factory_name}</p>
            <p>License Code: {siCode.factory_license_number}</p>
            <p>Product : {siCode.finish_product}</p>
          </div>
          
          <Row style={{marginRight: 24, marginLeft: 24}}>
            <Col span={24}>
              <ErrorMessages message={errorMessages} style={{ marginBottom: 8 }} />
              <Row gutter={[8, 8]} justify={"center"} style={{marginBottom: 16}}>
                <Col>
                  <Button color='primary' fill='outline' onClick={handleDownloadGeoJSON}>
                    <DownloadOutlined />
                    GeoJSON
                  </Button>
                </Col>
                <Col>
                  <Button color='primary' fill='outline' onClick={handleDownloadReportDDR}>
                    <DownloadOutlined />
                    DDR 
                  </Button>
                </Col>
              </Row>
              
              <Row gutter={[8, 8]} justify={"center"} style={{marginBottom: 16}}>
                <Selector 
                  multiple 
                  options={PLANTATION_LAYER_OPTIONS}
                  onChange={arr => setGeoLayers(arr)}/>
              </Row>
              
              <div style={{...cardStyle, marginBottom: 16}}>
                <div style={{ height: 700, width: "100%" }}>
                  <MapContainer zoom={5} maxZoom={20} minZoom={5} center={MAP_CENTER} attributionControl={false} >
                    <LayersControl>
                      <LayersControl.BaseLayer checked name="Google Map">
                        <LayerGroup>
                          <TileLayer
                            maxZoom={20}
                            attribution="Google Maps"
                            url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                          />
                          <ListAntd
                            dataSource={plantation}
                            renderItem={renderPlantationPolygons}
                          />
                          
                          <ListAntd
                            dataSource={deForestLayer}
                            renderItem={renderDeforestationLayer}
                          />

                          <ListAntd
                            dataSource={nationalForestLayer}
                            renderItem={renderNationalForestLayer}
                          />

                          <ListAntd
                            dataSource={nonHuntingLayer}
                            renderItem={renderNonHuntingLayer}
                          />

                          <ListAntd
                            dataSource={protectedAreaLayer}
                            renderItem={renderProtectedAreaLayer}
                          />

                          <ListAntd
                            dataSource={forestCoverLayer}
                            renderItem={renderForestCoverLayer}
                          />

                          <ListAntd
                            dataSource={reservedForestLayer}
                            renderItem={renderReservedForestLayer}
                          />
                        </LayerGroup>
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Google Map Satellite">
                        <LayerGroup>
                          <TileLayer
                            maxZoom={20}
                            attribution="Google Maps Satellite"
                            url="https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                          />
                          <ListAntd
                            dataSource={plantation}
                            renderItem={renderPlantationPolygons}
                          />

                          <ListAntd
                            dataSource={deForestLayer}
                            renderItem={renderDeforestationLayer}
                          />
                          
                          <ListAntd
                            dataSource={nationalForestLayer}
                            renderItem={renderNationalForestLayer}
                          />

                          <ListAntd
                            dataSource={nonHuntingLayer}
                            renderItem={renderNonHuntingLayer}
                          />

                          <ListAntd
                            dataSource={protectedAreaLayer}
                            renderItem={renderProtectedAreaLayer}
                          />

                          <ListAntd
                            dataSource={forestCoverLayer}
                            renderItem={renderForestCoverLayer}
                          />

                          <ListAntd
                            dataSource={reservedForestLayer}
                            renderItem={renderReservedForestLayer}
                          />
                          
                          <TileLayer url="https://www.google.com/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                        </LayerGroup>
                      </LayersControl.BaseLayer>
                    </LayersControl>
                    {plantation.map(item => {
                      const { latitude, longitude } = _.get(item, 'plantation.location', {})
                      return (latitude && longitude) ? (
                        <Marker 
                          position={[latitude, longitude]} 
                          icon={iconMarker}>
                          <Popup>{`[${item.plantation.code}]`}<br />{item.plantation.sub_district_detail}</Popup>
                        </Marker>
                      ) : <></>
                    })}
                  </MapContainer>
                </div>
              </div>
              
              {/* <List header='DEL' style={{ background: '#F0F3F0', marginTop: "30px" }}>
                {!datadDel.length && <Empty />}
                <InfiniteScroll
                  dataLength={datadDel.length}
                  loader={
                    <Skeleton
                      paragraph={{
                        rows: 1,
                      }}
                      active
                    />
                  }
                >
                  {datadDel.map(item => (
                    <List.Item
                      key={item.id}
                      description={
                        <div>
                          <p>Product: {item.product_name}</p>
                          <p>Weight: {item.sent_weight} KG</p>
                          <p>Factory delivery date: {item.sent_date}</p>
                          <p>Certificate: {item.certificate_code}</p>
                        </div>
                      }
                    >
                      DEL : {item.code}
                    </List.Item>
                  ))}
                </InfiniteScroll>
              </List>

              <List header='PLANTATION' style={{ background: '#F0F3F0' }}>
                {!plantation.length && <Empty />}
                <InfiniteScroll
                  dataLength={plantation.length}
                  loader={
                    <Skeleton
                      paragraph={{
                        rows: 1,
                      }}
                      active
                    />
                  }
                >
                  {plantation.map((item, i) => (
                    <List.Item
                      key={item.id}
                      description={
                        <div>
                          <p>Address: {item.plantation.sub_district_detail}</p>
                          <p>Area: {item.plantation.overall_area} HA</p>
                          <p>Title deed: {item.plantation.title_deed_type_name}</p>
                          <p>Weight: {formatComma(item.weight)}</p>
                        </div>
                      }
                    >
                      {item.plantation.code}
                    </List.Item>
                  ))}
                </InfiniteScroll>
              </List> */}
            </Col>
          </Row>
        </Layout>
      </Spin>
    </Space>
    </Content>

      <Footer style={{ textAlign: 'center' }}>
        Copyright © 2023 Tailor Solutions, Inc. All rights reserved.
      </Footer>
    </Layout>
  )
}


