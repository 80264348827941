import { Card, message, Pagination, Space, List, theme, Tooltip, Popconfirm, Row, Col, Flex } from 'antd';
import React from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, FilePdfOutlined, FileTextOutlined, FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import TSTable from '../common/TSTable';
import { URL_DELIVERY } from '../../constants/urls';
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from '../../constants/string';
import ColorButton from '../common/ColorButton';
import { GET, DELETE } from '../../frameworks/HttpClient';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import Meta from 'antd/es/card/Meta';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import dayjs from 'dayjs';
import ImportDELModal from './ImportDELModal';
import FactoryDeliveryModal from './FactoryDeliveryModal';

export default function FactoryDeliveryOrderView (props) {
  const { token: { colorSuccess, colorInfo, colorWarning } } = theme.useToken();

  const tableRef = React.useRef(null);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [dataDel ,setDataDel] = React.useState([]);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1)
  const [openImportDataModal, setOpenImportDataModal] = React.useState(false)
  const [detailTarget, setDetailTarget] = React.useState(null);
  const { t } = useTranslation();
  // const key = 'updatable';

  const fetchDataDEL = async(page) => {
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}?page_size=5&page=${page ? page : 1}`)
      setDataDel(response.data.results)
      setTotal(response.data.total)
    } catch (error) {}
  } 

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    fetchDataDEL();
  },[])

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("menu.delivery_order"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("menu.delivery_order"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("delivery_order")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      refreshData();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }

  }

  const columns = [
    {
      title: "Document No.",
      dataIndex: 'code',
      sorter: true,
      searcher: true,
    },
    {
      title: "Supplier",
      dataIndex: 'sender',
      sorter: true,
      searcher: true,
    },
    {
      title: 'Flexibag No.',
      dataIndex: 'ri_no',
      searcher: true,
    },
    {
      title: 'Seal No.',
      dataIndex: 'acfl_no',
      searcher: true,
    },
    {
      title: 'Net Weight',
      dataIndex: 'sent_weight'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      skipReport: true,
      render: (value) => (
        <div style={{ textAlign: 'center' }}>
          <Space>
           {contextHolder}
            <Tooltip title={t('edit')}>
              <ColorButton
                type='primary'
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => navigate(`${PATH.DELIVERY_ORDER}/${value}`)} />
            </Tooltip>
            <Tooltip title={t("plantations.detail")}>
              <ColorButton
                override={colorInfo}
                type='primary'
                icon={<FileTextOutlined/>}
                onClick={() => setDetailTarget(value)}/>
            </Tooltip>
            <Tooltip title={t("actions.delete_data")}>
              <Popconfirm
                title={t("confirm_cancellation_of_the_rubber_delivery")}
                onConfirm={() => handleDelete(value)}
                okText={t("yes")}
                okButtonProps={{ danger: true }}
                cancelText={t("no")}>
                <ColorButton
                  type='primary'
                  danger
                  icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        </div>
      )
    }
  ]

  return (
    <div>
      <Space>
        <ColorButton 
          type="primary"
          icon={<PlusCircleOutlined/>}
          override={colorSuccess}
          onClick={() => setOpenImportDataModal(true)}
        >
          {t('import_data')}
        </ColorButton>
        <ColorButton 
          type="dashed"
          icon={<DownloadOutlined />}
          onClick={() => window.location.href ="/traztru_trader_import_template.xlsx"}>
          {t('download_template')}
        </ColorButton>
      </Space>
    {isOnMobile ? (
      <div>
        <List 
          dataSource={dataDel}
          renderItem={(item) => (
            <List.Item>
              <Card 
                title={<p>DEL{item.code}</p>}
                style={{width:'100%'}}
                onClick={() => setDetailTarget(item.id)}
              >
                <Meta 
                  description={
                    <div>
                      <div>Document No.: {item.code}</div>
                      <div>Flexibag No.: {item.ri_no}</div>
                      <div>Seal No.: {item.acfl_no}</div>
                      <div>Net Weight: {item.sent_weight}</div>
                    </div>
                  }/>
              </Card>   
            </List.Item>
          )}>
        </List>
         <div style={{textAlign:'center'}}>
            <Pagination
                defaultPageSize={5}
                defaultCurrent={1}
                current={page}
                total={total}
                onChange={(page) => {
                  setPage(page)
                  fetchDataDEL(page)
                }}
              />
            </div>
      </div>
    ):(<>
      <Space style={{ float: 'right' }}>
        <Row gutter={8}>
          <Col>
            <ColorButton
              icon={<FilePdfOutlined />}
              style={{float: "right"}}
              onClick={() => exportTable("pdf")}>
              PDF
            </ColorButton>
          </Col>
          <Col>
            <ColorButton
              icon={<FileExcelOutlined />}
              style={{float: "right", marginBottom : 16}}
              onClick={() => exportTable("excel")}>
              Excel
            </ColorButton>
          </Col>
        </Row>
      </Space>
      <TSTable
        columns={columns}
        ref={tableRef}
        url={URL_DELIVERY.DELIVERY_TRANSACTION}
        rowKey={'id'}
        size={'small'}
        pageSize={10}/>
      </>
    )}

    <ImportDELModal
      open={openImportDataModal}
      onClose={() => {
      refreshData()
      setOpenImportDataModal(false)
      }}/>

    <FactoryDeliveryModal
      open={detailTarget != null}
      target={detailTarget}
      onClose={() => {
        refreshData()
        setDetailTarget(null)
      }}/>
    </div>
  )
}