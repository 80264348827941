import { Form, Input, message, Modal, Spin, Upload, Table, Button, Space } from "antd";
import React from "react";
import fileDownload from 'js-file-download';
import { useTranslation } from "react-i18next";
import ErrorMessages from "../common/ErrorMessages";
import { InboxOutlined, FileTextOutlined } from "@ant-design/icons";
import { POST } from "../../frameworks/HttpClient";
import { URL_DELIVERY } from "../../constants/urls";


export default function ImportDELModal (props) {
  const {
    open,
    onClose
  } = props;

  const errorColumns = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
    },
    {
      title: 'Column',
      dataIndex: 'column',
      key: 'column',
    },
    {
      title: 'Error Detail',
      dataIndex: 'error',
      key: 'error',
    },
  ];


  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [importErrors, setImportErrors] = React.useState(null);
  const [modal, modalContextHolder] = Modal.useModal();

  const handleUploadFile = async () => {
    if (!file) {
      setErrorMessages(t('file_empty_error'))
      return
    }
    setLoading(true);
    setErrorMessages(null);
    try {
      const { code } = await form.validateFields();
      const response = await POST(URL_DELIVERY.DELIVERY_IMPORT_EXCEL, { excel: file, code }, true)
      if (response.data.success) { 
        messageApi.success(t('successfully_imported')) 
        onClose();
      }
      else {
        messageApi.error(t("fail_to_imported"))
        setImportErrors(response.data.errors)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleChooseFile = async ({ file, ...args }) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      setFile(file)
      args.onSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages)
      args.onError();
    } finally {
      setLoading(false);
    }
  }

  const generateCSV = () => {
    const headers = errorColumns.map(column => column.title).join(',') + '\n';
    const rows = importErrors.map(row => errorColumns.map((field) => row[field.key] || '').join(',')).join('\n');
    const csvFile = headers + rows;
    return csvFile
  }

  React.useEffect(() => {
    if (open) {
      setErrorMessages(null);
      setFile(null);
      setImportErrors(null);
      form.resetFields();
    }
  }, [open])

  React.useEffect(() => {
    if(importErrors && importErrors.length > 0) {
      modal.error({
        width: 1000,
        title: (
          <Space>
            {t('import_error_summary')}
            <Button onClick={() => {
              const csvFile = generateCSV()
              fileDownload(csvFile, "traztru_trader_import_error.csv")
            }}>
              Download as text
            </Button>
          </Space>
        ),
        content: (
          <Table
            style={{ marginTop: 16 }}
            columns={errorColumns}
            dataSource={importErrors}
            size={"small"}
            pageSize={10} />
        ),
      });
    }
  }, [importErrors])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon={true}
      cancelText={t('cancel')}
      okText={t('submit')}
      cancelButtonProps={{ loading: loading }}
      okButtonProps={{ loading: loading }}
      title={t('import_data')}
      onOk={handleUploadFile}
    >
      {contextHolder}
      {modalContextHolder}
      <ErrorMessages message={errorMessages} />
      <Spin spinning={loading}>
        <Form form={form}>
          <Form.Item label='Document No' name='code' rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
        </Form>
        <Upload.Dragger 
          style={{ marginTop: '1rem'}} 
          name='file'
          accept=".xlsx"
          multiple={false}
          customRequest={handleChooseFile}
          showUploadList={false}
        >
          { !file ? (
            <div>
              <p className='ant-upload-drag-icon'><InboxOutlined /></p>
              <p className='ant-upload-text'>{t('dragger_title')}</p>
              <p className='ant-upload-hint'>{t('dragger_import_del_hint')}</p>
            </div>
          ) : (
            <div>
              <p className='ant-upload-drag-icon'><FileTextOutlined/></p>
              <p className='ant-upload-text'>{file.name}</p>
            </div>
          )}
        </Upload.Dragger>
      </Spin>
    </Modal>
  )
}