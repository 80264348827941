import React from 'react'
import { Layout } from 'antd'
import {
  FileDoneOutlined,
  BankOutlined,
  LogoutOutlined,
  SendOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from '../routes/CustomRoutes';
import "./../styles.css"
import FactoryListView from '../views/FactoryListView';
import { useUserContext } from '../hooks/UserSettingProvider';
import MainMenu from '../components/common/MainMenu';
import * as _ from 'lodash';
import FactoryDeliveryOrderView from '../components/factory/FactoryDeliveryOrderView';
import FactoryShippingInstructionView from '../components/factory/FactoryShippingInstructionView';
import DeliveryListView from '../views/DeliveryListView';
import DashboardTabCcView from '../views/DashboardTabCcView';
import FactoryCustomerView from '../components/factory/FactoryCustomerView';
import { useTranslation } from 'react-i18next';

export default function MenuScreen() {
	const { user } = useUserContext();
  const isAdmin = !user.factory || user.is_superuser;
  const isFactory = user.factory ? true : false
	// Main menu
	const params = useParams();
  const navigate = useNavigate();
  const [current, setCurrent] = React.useState(null);
  const { t } = useTranslation();

  const renderContent = () => {
    switch (current) {
      // officer
      case PATH.FACTORIES.substring(1): return <FactoryListView />
      case PATH.OFFICER_DELIVERY_ORDER.substring(1): return <DeliveryListView />
      
      // factory
      case PATH.FACTORY_DELIVERY_ORDER.substring(1): return <FactoryDeliveryOrderView/>
      case PATH.FACTORY_SHIPPING_INSTRUCTION.substring(1): return <FactoryShippingInstructionView/>
      case PATH.FACTORY_CUSTOMER.substring(1): return <FactoryCustomerView/>
      default: return null;
    }
  }

  const menus = [
    // officer menu
    { 
      key: PATH.FACTORIES.substring(1), 
      icon: <BankOutlined />, 
      label: t('menu.factory'),
      permission: (isAdmin).toString(),
      disableMobile: true
    },
    {
      key: PATH.OFFICER_DELIVERY_ORDER.substring(1),
      icon: <SendOutlined />,
      label:t('menu.delivery'),
      permission: (isAdmin).toString(),
      disableMobile: true
    },
    // factory menu
    {
      key: PATH.FACTORY_DELIVERY_ORDER.substring(1),
      icon: <FileDoneOutlined />,  
      label: t('menu.delivery_order'),
      permission: (isFactory).toString(),
      disableMobile: true
    },
    {
      key: PATH.FACTORY_SHIPPING_INSTRUCTION.substring(1),
      icon: <LogoutOutlined />,  
      label: t('menu.si'),
      permission: (isFactory).toString(),
      disableMobile: true
    },
    // {
    //   key: PATH.FACTORY_CUSTOMER.substring(1),
    //   icon: <UserOutlined />,  
    //   label: t('menu.customer'),
    //   permission: (isFactory).toString(),
    //   disableMobile: true
    // },
  ];

  const onMenuSelected = (e) => {
    if (e.key !== current) {
      setCurrent(e.key);
      navigate(`/${e.key}`);
    }
  }

  const defaultRouting = () => {
    if (isAdmin) {
      navigate(PATH.FACTORIES)
    } else {
      navigate(PATH.FACTORY_DELIVERY_ORDER)
    }
  }

  const autoRoute = () => {
    const menu = menus
      .filter(menu => menu.permission != "false")
      .find(menu => params.menu === menu.key);
    if (!menu) {
      defaultRouting();
    } else {
      setCurrent(menu.key);
    }
  }

  React.useEffect(() => {
    if (params) {
      autoRoute();
    } else {
      defaultRouting()
    }
  }, [params])
  
  return (
    <Layout>
      <MainMenu 
        onMenuSelected={onMenuSelected}
        currentMenu={current}
        menus={menus}
      >
        {renderContent()}
      </MainMenu>
    </Layout>
  )
}