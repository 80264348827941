import React from 'react'
import propTypes from "prop-types";
import { Alert, AutoComplete, Checkbox, Col, DatePicker, Form, Input, Modal,  Row,  Select,  theme } from "antd";
import Header from '../common/Header';
import { GET, POST, PUT } from '../../frameworks/HttpClient';
import { DATE_FORMAT, HUMAN_DATE_FORMAT } from '../../constants/string';
import _ from "lodash";
import CustomSearchSelect from '../common/CustomSearchSelect';
import { URL_CUSTOMER, URL_DELIVERY, URL_MEMBER } from '../../constants/urls';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function DeliveryModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [drivers, setDrivers] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [sender, setSender] = React.useState(null);
  const [certificateOptions, setCertificateOptions] = React.useState(null);
  const [sentDate, setSentDate] = React.useState([]);
  const [arrivalDate, setArrivaDate] = React.useState([]);
  const [needFSCCertificate, setNeedFSCCertificate] = React.useState(false);
  const { t, i18n } = useTranslation();

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();
  

  const handleSentDateChange = (sentDate) => {
    setSentDate(sentDate)
    setArrivaDate(null)
    form.resetFields(['receive_date']); 
    
  };
  const handleDelivery = async () => {
    setErrorMessages(null);
    setLoading(true);
    try {
      if(props.factory) {
        form.setFieldValue('receiver', props.factory)
      }
      const data = await form.validateFields();
      if (data['receive_date'])
        data['receive_date'] = data.receive_date.format(DATE_FORMAT)
      if (data['sent_date'])
        data['sent_date'] = data.sent_date.format(DATE_FORMAT)
      if (!props.data) {
        await POST(URL_DELIVERY.DELIVERY_TRANSACTION, data)
      } else {
        await PUT(`${URL_DELIVERY.DELIVERY_TRANSACTION}${props.data.id}/`, data)
      }
      props.onUpdated();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (props.open) {
      const data = props.data
      if (data){
        form.setFieldsValue({
          ...data, 
          receive_date: data.receive_date ? dayjs(data.receive_date, DATE_FORMAT) : null,
          sent_date: data.sent_date ? dayjs(data.sent_date, DATE_FORMAT) : null,
        })
      }
    } else {
      setErrorMessages(null);
      form.resetFields();
    }
  }, [props.open])

  return (
    <Modal
      width={760}
      okText={props.target ? t("edit") : t("add")}
      okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      cancelText={t("cancel")}
      open={props.open}
      confirmLoading={loading}
      onOk={handleDelivery}
      onCancel={props.onClose}>
        <Header title={t("del")}></Header>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }

        <Form 
          form={form}
          style={{marginTop: 16}}
          layout="vertical"
          autoComplete="off"
          initialValues={{ is_agriac_supply_chain: true }}>
          <Row gutter={16}>
            <Col md={24} sm={24} xs={24}>
              <Form.Item name='receiver' label={t("factory")} rules={[{ required: true }]} hidden={props.factory != null}>
                <CustomSearchSelect
                  url={URL_MEMBER.FACTORY}
                  label='name' />
              </Form.Item>
            </Col>            
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='vehicle_license_plate' label={t("vehicle_registration_number")}>
                <Input/>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='driver_name' label={t("driver")}>
                <Input/>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='driver_phone' label={t("phone_number")}>
                <Input/>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='sent_date' label={t("export_date")} rules={[{ required: true }]}>
                <DatePicker
                  // disabledDate={disabledSentDate}
                  onChange={handleSentDateChange}
                  style={{ width: '100%' }}
                  format={HUMAN_DATE_FORMAT}/>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='receive_date' label={t("arrival_date")} rules={[{ required: true }]}>
                <DatePicker
                  // disabledDate={disabledArrivalDate}
                  onChange={(arrivalDate)=>{
                    setArrivaDate(arrivalDate)
                  }}
                  style={{ width: '100%' }}
                  format={HUMAN_DATE_FORMAT}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
    </Modal>
  )
}
DeliveryModal.defaultProps = {
  open: false,
  data: null,
  factory: null,
  onUpdated: () => {},
  onClose: () => {},
}

DeliveryModal.propTypes = {
  open: propTypes.bool,
  data: propTypes.object,
  factory: propTypes.object,
  onUpdated: propTypes.func,
  onClose: propTypes.func,
}